define("discourse/plugins/discourse-password-expiry/discourse/components/password-expiry-warning", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax-error", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _object, _ajaxError, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row">
    <div class="alert alert-error">
      {{#if this.expired}}
        {{i18n "password_expiry.password_expired"}}
      {{else}}
        {{i18n "password_expiry.password_expire_warning" count=this.daysLeft}}
      {{/if}}
  
      {{#if this.changing}}
        {{i18n "password_expiry.sending_email"}}
      {{else if this.changed}}
        {{i18n "password_expiry.email_sent" email=this.sentToEmail}}
      {{else}}
        <a {{action "changePassword"}} href="">{{i18n
            "password_expiry.change_password"
          }}</a>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "NSxEPhYI",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n\"],[41,[30,0,[\"expired\"]],[[[1,\"      \"],[1,[28,[35,2],[\"password_expiry.password_expired\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"password_expiry.password_expire_warning\"],[[\"count\"],[[30,0,[\"daysLeft\"]]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"changing\"]],[[[1,\"      \"],[1,[28,[35,2],[\"password_expiry.sending_email\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"changed\"]],[[[1,\"      \"],[1,[28,[35,2],[\"password_expiry.email_sent\"],[[\"email\"],[[30,0,[\"sentToEmail\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,3],[24,6,\"\"],[4,[38,4],[[30,0],\"changePassword\"],null],[12],[1,[28,[35,2],[\"password_expiry.change_password\"],null]],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"if\",\"i18n\",\"a\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-password-expiry/discourse/components/password-expiry-warning.hbs",
    "isStrictMode": false
  });
  class PasswordExpiryWarning extends _component.default {
    changing = false;
    changed = false;
    sentToEmail = "";
    daysLeft(passwordExpiresAt) {
      return Math.ceil(moment(passwordExpiresAt).diff(moment(), "days", true));
    }
    static #_ = (() => dt7948.n(this.prototype, "daysLeft", [(0, _decorators.default)("currentUser.password_expires_at")]))();
    expired(passwordExpiresAt) {
      return moment().isAfter(moment(passwordExpiresAt));
    }
    static #_2 = (() => dt7948.n(this.prototype, "expired", [(0, _decorators.default)("currentUser.password_expires_at")]))();
    changePassword() {
      this.set("changing", true);
      this.currentUser.findDetails().then(user => {
        this.set("sentToEmail", user.email);
        user.changePassword().then(() => this.set("changed", true)).catch(_ajaxError.popupAjaxError).finally(() => this.set("changing", false));
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "changePassword", [_object.action]))();
  }
  _exports.default = PasswordExpiryWarning;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PasswordExpiryWarning);
});